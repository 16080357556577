import { useEffect, useState } from "react"
import { IFormProps, IFormState, IFormAny, IFormUpdateState, IFormUpdateValues, IFormObject } from "interfaces/components/form"

export const Form = ({ initial, validate = {}, disabled, onSubmit, children }: IFormProps) => {
    const stateInitial: IFormState = { errors: {}, values: initial || {}, updated: {} }
    const [state, setState] = useState<IFormState>(stateInitial)

    disabled = disabled || state.disabled

    useEffect(() => {
        if (initial) {
            setState(stateInitial)
        }
    }, [initial])

    const updateState: IFormUpdateState = (values) => {
        setState({ ...state, ...values })
    }

    const updateErrors: IFormUpdateValues = (errors) => {
        updateState({ errors: { ...state.errors, ...errors } })
    }

    const updateValues: IFormUpdateValues = (values) => {
        if (!disabled) {
            updateState({ values: { ...state.values, ...values }, updated: { ...state.updated, ...values } })
        }
    }

    const handleChange: IFormAny = (value) => {
        if (value?.preventDefault) {
            value.preventDefault()
        }

        if (value?.target?.name) {
            const errors = { ...state.errors }

            delete errors[value?.target?.name]

            const values = { [value.target.name]: value?.target?.value }

            updateState({
                values: { ...state.values, ...values },
                updated: { ...state.updated, ...values },
                errors
            })
        }
    }

    const submit: IFormAny = (value) => {
        if (value?.preventDefault) {
            value.preventDefault()
        }

        const errors: IFormObject = {}

        Object.keys(validate).map((x) => {
            if (validate[x]) {
                if (!new RegExp(validate[x]).test(state.values?.[x])) {
                    errors[x] = "Неверное значение"
                }
            }
        })

        if (Object.keys(errors).length) {
            updateErrors(errors)

            return
        }

        if (!disabled) {
            updateState({ disabled: true })

            const start = async () => {
                if (onSubmit) {
                    await onSubmit(state)
                }

                updateState({ disabled: false })
            }

            start()
        }
    }

    return <form onSubmit={submit}>{children({ ...state, disabled, updateState, updateErrors, updateValues, handleChange, submit })}</form>
}
