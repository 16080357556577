import { Card } from "components/Card"
import { Form } from "components/Form"
import { Stack } from "@mui/material"
import { ITableProps } from "interfaces/components/table"

import { TableFooter } from "./TableFooter"
import { TableHeader } from "./TableHeader"
import { TableContent } from "./TableContent"

export const Table = ({ initial, filters, items, headers, count, searchPlaceholder, onCreate, onSubmit, disabled }: ITableProps) => {
    return (
        <Card>
            <Form initial={initial} disabled={disabled} onSubmit={onSubmit}>
                {({ values, updateState, updateValues, handleChange, submit, disabled }) => {
                    return (
                        <Stack>
                            <TableHeader
                                values={values}
                                initial={initial}
                                filters={filters}
                                searchPlaceholder={searchPlaceholder}
                                handleChange={handleChange}
                                handleCreate={onCreate}
                                updateState={updateState}
                                disabled={disabled}
                            />

                            <TableContent items={items} headers={headers} />

                            <TableFooter values={values} count={count} updateValues={updateValues} submit={submit} disabled={disabled} />
                        </Stack>
                    )
                }}
            </Form>
        </Card>
    )
}

export { TableContent } from "./TableContent"