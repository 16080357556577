// project import
import Default from "./default"
import Theme5 from "./theme5"

// types
import { PaletteThemeProps } from "interfaces/theme"
import { PalettesProps } from "@ant-design/colors"
import { ThemeMode, PresetColor } from "interfaces/config"

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors: PalettesProps, presetColor: PresetColor, mode: ThemeMode): PaletteThemeProps => {
    switch (presetColor) {
        case "theme5":
            return Theme5(colors, mode)
        default:
            return Default(colors)
    }
}

export default Theme
