import menu from "./reducers/menu"
import session from "./reducers/session"
import centers from "./reducers/centers"

import { combineReducers, configureStore } from "@reduxjs/toolkit"

const rootReducer = combineReducers({
    menu,
    session,
    centers
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer
    })
}

export type AppStore = ReturnType<typeof setupStore>
export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = AppStore["dispatch"]
