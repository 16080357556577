import { Stack } from "@mui/material"
import { ReactComponent as LogoName } from "assets/icons/logoname.svg"

const AuthHeader = () => (
    <Stack sx={{ p: 3, pb: 0 }} direction="row">
        <LogoName />
    </Stack>
)

export default AuthHeader
