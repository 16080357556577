import { ILabelProps } from "interfaces/components/label"
import { Stack, Typography } from "@mui/material"

export const Label = ({ name, width, children }: ILabelProps) => {
    return (
        <Stack sx={{ width: width || "100%" }} spacing={1}>
            {name && (
                <Typography variant="h6" color="secondary" noWrap>
                    {name}
                </Typography>
            )}

            {children}
        </Stack>
    )
}
