// material-ui
import { Theme } from "@mui/material/styles"

// ==============================|| OVERRIDES - TOOLTIP ||============================== //

export default function Tooltip(theme: Theme) {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.palette.background.paper
                }
            }
        }
    }
}
