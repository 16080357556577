import axios from "libs/axios"

import { IResponse } from "../interfaces/services/responses/base"
import { ICenterFiltered } from "interfaces/main/center"
import {
    IUserOrdersConfirmationResponse,
    IUserOrdersPriceResponse,
    IUserOrdersSearchResponse,
    IUserProfileExtendedResponse,
    IUserTransactionsResponse
} from "interfaces/services/responses/user"
import { IUserFiltered, IUserStatistic } from "interfaces/main/user"
import { IOrderFiltered, IOrderReservationFiltered } from "interfaces/main/order"
import { IUserOrdersEditRequest, IUserOrdersSearchRequest, IUserTransactionsRequest } from "interfaces/services/requests/user"

export const UserProfile = async (): Promise<IResponse<IUserFiltered>> => {
    const result = await axios("user/profile", { method: "GET" })

    return result.data
}

export const UserProfileExtended = async (): Promise<IResponse<IUserProfileExtendedResponse>> => {
    const result = await axios("user/profile?extended=true", { method: "GET" })

    return result.data
}

export const UserStatistic = async (): Promise<IResponse<IUserStatistic>> => {
    const result = await axios("user/statistic", { method: "GET" })

    return result.data
}

export const UserTransactions = async (params: IUserTransactionsRequest): Promise<IResponse<IUserTransactionsResponse>> => {
    const result = await axios("user/transactions", { method: "GET", params })

    return result.data
}

export const UserCenters = async (): Promise<IResponse<ICenterFiltered[]>> => {
    const result = await axios("user/centers", { method: "GET" })

    return result.data
}

export const UserOrdersSearch = async (params: IUserOrdersSearchRequest): Promise<IResponse<IUserOrdersSearchResponse>> => {
    const result = await axios("user/orders", { method: "GET", params })

    return result.data
}

export const UserOrdersGet = async (id: string): Promise<IResponse<IOrderFiltered>> => {
    const result = await axios(`user/orders/${id}`, { method: "GET" })

    return result.data
}

export const UserOrdersCreate = async (data: IUserOrdersEditRequest): Promise<IResponse<IOrderFiltered>> => {
    const result = await axios("user/orders", { method: "POST", data })

    return result.data
}

export const UserOrdersUpdate = async (id: string, data: IUserOrdersEditRequest): Promise<IResponse<IOrderFiltered>> => {
    const result = await axios(`user/orders/${id}`, { method: "PATCH", data })

    return result.data
}

export const UserOrdersAction = async (id: string, action: string): Promise<IResponse<IOrderFiltered>> => {
    const result = await axios(`user/orders/${id}`, { method: "POST", data: { action } })

    return result.data
}

export const UserOrdersPrice = async (id: string): Promise<IResponse<IUserOrdersPriceResponse>> => {
    const result = await axios(`user/orders/${id}/price`, { method: "GET" })

    return result.data
}

export const UserOrdersReservation = async (id: string): Promise<IResponse<IOrderReservationFiltered>> => {
    const result = await axios(`user/orders/${id}/reservation`, { method: "GET" })

    return result.data
}

export const UserOrdersConfirmation = async (id: string): Promise<IResponse<IUserOrdersConfirmationResponse>> => {
    const result = await axios(`user/orders/${id}/confirmation`, { method: "GET" })

    return result.data
}
