import { ReactNode, useMemo } from "react"

import { CssBaseline, StyledEngineProvider } from "@mui/material"
import { createTheme, ThemeOptions, ThemeProvider, Theme, TypographyVariantsOptions } from "@mui/material/styles"

import Palette from "./palette"
import Typography from "./typography"
import CustomShadows from "./shadows"
import componentsOverride from "./overrides"

import { CustomShadowProps } from "interfaces/theme"
import { ThemeMode } from "interfaces/config"

type ThemeCustomizationProps = {
    children: ReactNode
}

export default function ThemeCustomization({ children }: ThemeCustomizationProps) {
    const theme: Theme = useMemo<Theme>(() => Palette(ThemeMode.LIGHT, "theme5"), [])

    const themeTypography: TypographyVariantsOptions = useMemo<TypographyVariantsOptions>(
        () => Typography(ThemeMode.LIGHT, "'Roboto', sans-serif", theme),
        []
    )

    const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(() => CustomShadows(theme), [theme])

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1440
                }
            },
            direction: "ltr",
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8
                }
            },
            palette: theme.palette,
            customShadows: themeCustomShadows,
            typography: themeTypography
        }),
        [theme, themeTypography, themeCustomShadows]
    )

    const themes: Theme = createTheme(themeOptions)
    themes.components = componentsOverride(themes)

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}
