import HeaderBar from "./HeaderBar"
import HeaderContent from "./HeaderContent"

import { useTheme } from "@mui/material/styles"
import { ReactNode } from "react"
import { menuSlice } from "store/reducers/menu"
import { useAppDispatch, useAppSelector } from "interfaces/redux"
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import { AppBar, Toolbar, useMediaQuery, AppBarProps, IconButton, Stack } from "@mui/material"

const MainHeader = () => {
    const theme = useTheme()
    const downLG = useMediaQuery(theme.breakpoints.down("lg"))

    const dispatch = useAppDispatch()

    const { drawer } = useAppSelector((state) => state.menu)

    const mainHeader: ReactNode = (
        <Toolbar>
            <Stack direction="row" spacing={2} sx={{ justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <IconButton
                    onClick={() => dispatch(menuSlice.actions.setDrawer(!drawer))}
                    edge="start"
                    color="secondary"
                    sx={{ color: "text.primary", bgcolor: drawer ? "grey.300" : "grey.100", ml: { xs: 0, lg: -2 } }}
                >
                    {!drawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </IconButton>

                <HeaderContent />
            </Stack>
        </Toolbar>
    )

    const appBar: AppBarProps = {
        position: "fixed",
        color: "inherit",
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            zIndex: 1200,
            width: drawer ? "calc(100% - 260px)" : { xs: "100%", lg: "calc(100% - 60px)" }
        }
    }

    return (
        <>
            {!downLG ? (
                <HeaderBar open={drawer} {...appBar}>
                    {mainHeader}
                </HeaderBar>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    )
}

export default MainHeader
