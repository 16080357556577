import { Box } from "@mui/material"
import { useAppSelector } from "interfaces/redux"

import { ReactComponent as Logo } from "assets/icons/logo.svg"
import { ReactComponent as LogoName } from "assets/icons/logoname.svg"

const MainDrawerHeader = () => {
    const { drawer } = useAppSelector((state) => state.menu)

    return (
        <Box
            sx={{
                my: 0.5,
                height: "52px",
                pl: drawer ? 3 : 0,
                justifyContent: drawer ? "normal" : "center",
                alignItems: "center",
                display: "flex"
            }}
        >
            {drawer ? <LogoName /> : <Logo width="36px" height="36px" />}
        </Box>
    )
}

export default MainDrawerHeader
