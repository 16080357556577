import MiniDrawer from "./MiniDrawer"
import DrawerHeader from "./DrawerHeader"
import DrawerContent from "./DrawerContent"

import { useEffect, useMemo } from "react"
import { useTheme } from "@mui/material/styles"
import { menuSlice } from "store/reducers/menu"
import { useAppDispatch, useAppSelector } from "interfaces/redux"
import { Box, Drawer, useMediaQuery } from "@mui/material"

const MainDrawer = () => {
    const theme = useTheme()
    const matchDownMD = useMediaQuery(theme.breakpoints.down("md"))

    const dispatch = useAppDispatch()

    const menu = useAppSelector((state) => state.menu)
    const { drawer } = menu

    useEffect(() => {
        if (!matchDownMD) {
            dispatch(menuSlice.actions.setDrawer(true))
        }
    }, [matchDownMD])

    const drawerHeader = useMemo(() => <DrawerHeader />, [])
    const drawerContent = useMemo(() => <DrawerContent />, [])

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }}>
            {!matchDownMD ? (
                <MiniDrawer variant="permanent" open={drawer}>
                    {drawerHeader}
                    {drawerContent}
                </MiniDrawer>
            ) : (
                <Drawer
                    variant="temporary"
                    open={drawer}
                    onClose={() => dispatch(menuSlice.actions.setDrawer(!drawer))}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        display: { xs: "block", lg: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: "260px",
                            borderRight: `1px solid ${theme.palette.divider}`,
                            backgroundImage: "none",
                            boxShadow: "inherit"
                        }
                    }}
                >
                    {drawerHeader}
                    {drawerContent}
                </Drawer>
            )}
        </Box>
    )
}

export default MainDrawer
