import { Loader } from "components"
import { useAppSelector } from "interfaces/redux"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const RestrictGuard = ({ minRole, redirect, children }: { minRole?: number; redirect?: string; children: JSX.Element }) => {
    const [loading, setLoading] = useState<boolean>(true)

    const session = useAppSelector((state) => state.session)
    const [location, navigate] = [useLocation(), useNavigate()]

    useEffect(() => {
        if (session.user.role || session.user.role == 0) {
            if (minRole && session.user.role >= minRole) {
                setLoading(false)

                return
            }
        }

        navigate(redirect || process.env.REACT_APP_USER_HOME || "", { replace: true })
    }, [session.user.role, location])

    if (loading) {
        return <Loader />
    }

    return children
}
