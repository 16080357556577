import { useEffect, useMemo } from "react"
import { useAppSelector } from "interfaces/redux"
import { useLocation, useNavigate } from "react-router-dom"
import { IDrawerCategoryProps, IDrawerItemProps, IDrawerSchemaCategory, IDrawerSchemaItem } from "interfaces/sections/main/drawer"
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useTheme } from "@mui/material"
import { AdminDrawerSchema, MainDrawerSchema } from "data/drawer"
import { CalendarOutlined } from "@ant-design/icons"

const Item = ({ icon, name, color, selected, mini, onClick }: IDrawerItemProps) => {
    if (mini) {
        return (
            <ListItem disablePadding>
                <ListItemButton
                    onClick={onClick}
                    selected={selected}
                    sx={{
                        py: 0.5,
                        zIndex: 1201,
                        alignItems: "center",
                        justifyContent: "center",

                        "&:hover": {
                            bgcolor: "transparent"
                        },

                        "&.Mui-selected": {
                            "&:hover": {
                                bgcolor: "transparent"
                            },
                            bgcolor: "transparent"
                        }
                    }}
                >
                    <ListItemIcon
                        sx={{
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                                bgcolor: "secondary.lighter"
                            },
                            ...(selected && {
                                bgcolor: "primary.lighter",
                                "&:hover": {
                                    cursor: "default",
                                    bgcolor: "primary.lighter"
                                }
                            })
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>
        )
    }

    return (
        <ListItem disablePadding>
            <ListItemButton
                onClick={onClick}
                selected={selected}
                sx={{
                    zIndex: 1201,
                    pl: "28px",
                    py: 1,

                    "&:hover": {
                        bgcolor: "primary.lighter"
                    },

                    "&.Mui-selected": {
                        bgcolor: "primary.lighter",
                        borderRight: `2px solid ${color}`,
                        "&:hover": {
                            cursor: "default",
                            bgcolor: "primary.lighter"
                        }
                    }
                }}
            >
                <ListItemIcon sx={{ minWidth: 28 }}>{icon}</ListItemIcon>

                <ListItemText primary={<Typography variant="h6">{name}</Typography>} />
            </ListItemButton>
        </ListItem>
    )
}

const Category = ({ text, open }: IDrawerCategoryProps) => {
    if (open) {
        return (
            <Box sx={{ pl: 3 }}>
                <Typography variant="caption" color="secondary">
                    {text}
                </Typography>
            </Box>
        )
    }
}

const ClosedContent = ({ schema }: { schema: IDrawerSchemaCategory[] }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const { pathname } = useLocation()

    return (
        <Stack spacing={1}>
            {schema.map((category: IDrawerSchemaCategory, i) => (
                <Stack key={i} spacing={1}>
                    <Category text={category.name} open={false} />

                    {category.items.map((item: IDrawerSchemaItem, j) => {
                        const handleClick = () => {
                            navigate(item.path)
                        }

                        return (
                            <Item
                                key={j}
                                icon={<item.icon style={{ fontSize: "18px" }} />}
                                name={item.name}
                                color={theme.palette.primary.main}
                                selected={item.path == pathname}
                                mini={true}
                                onClick={handleClick}
                            />
                        )
                    })}
                </Stack>
            ))}
        </Stack>
    )
}

const OpenedContent = ({ schema }: { schema: IDrawerSchemaCategory[] }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const { pathname } = useLocation()

    return (
        <Stack spacing={2} sx={{ pt: 2 }}>
            {schema.map((category: IDrawerSchemaCategory, i) => (
                <Stack key={i} spacing={1}>
                    <Category text={category.name} open={true} />

                    {category.items.map((item: IDrawerSchemaItem, j) => {
                        const handleClick = () => {
                            navigate(item.path)
                        }

                        return (
                            <Item
                                key={j}
                                icon={<item.icon style={{ fontSize: "16px" }} />}
                                name={item.name}
                                color={theme.palette.primary.main}
                                selected={item.path == pathname}
                                mini={false}
                                onClick={handleClick}
                            />
                        )
                    })}
                </Stack>
            ))}
        </Stack>
    )
}

const MainDrawerContent = () => {
    const { drawer } = useAppSelector((state) => state.menu)

    const schema: IDrawerSchemaCategory[] = []

    const role = useAppSelector((state) => state.session.user?.role || 0)

    if (role > 0) {
        schema.push(MainDrawerSchema)
    }

    if (role > 8) {
        schema.push(AdminDrawerSchema)
    }

    const Opened = useMemo(() => <OpenedContent schema={schema} />, [])
    const Closed = useMemo(() => <ClosedContent schema={schema} />, [])

    if (drawer) {
        return Opened
    }

    return Closed
}

export default MainDrawerContent
