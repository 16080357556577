import { ICentersParsed } from "interfaces/main/center"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: ICentersParsed = {}

export const centersSlice = createSlice({
    name: "centers",
    initialState,
    reducers: {
        set(state, action: PayloadAction<ICentersParsed>) {
            state = action.payload

            return state
        },

        reset(state) {
            state = { ...initialState }

            return state
        }
    }
})

export default centersSlice.reducer
