import { Chip } from "@mui/material"
import { LinkOutlined } from "@ant-design/icons"
import { IStatusProps } from "interfaces/components/status"

export const Status = ({ name, color, onClick }: IStatusProps) => (
    <Chip
        sx={{ width: "fit-content" }}
        size="small"
        variant="light"
        label={name}
        color={color}
        deleteIcon={onClick && <LinkOutlined />}
        onClick={onClick}
        onDelete={onClick}
    />
)
