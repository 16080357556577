import MainWrapper from "sections/main/MainWrapper"

import { lazy } from "react"
import { Outlet } from "react-router-dom"
import { Loadable } from "components"
import { RestrictGuard } from "context/guards"

const AdminUsers = Loadable(lazy(() => import("pages/admin/users")))
const AdminOrders = Loadable(lazy(() => import("pages/admin/orders")))

const AdminRoutes = {
    path: "/admin",
    element: (
        <RestrictGuard minRole={9} redirect="/">
            <MainWrapper>
                <Outlet />
            </MainWrapper>
        </RestrictGuard>
    ),
    children: [
        {
            path: "users",
            element: <AdminUsers />
        },
        {
            path: "orders",
            element: <AdminOrders />
        }
    ]
}

export default AdminRoutes
