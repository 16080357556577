import { Menu } from "interfaces/menu"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: Menu = { drawer: false }

export const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        setDrawer(state, action: PayloadAction<boolean>) {
            state.drawer = action.payload
        }
    }
})

export default menuSlice.reducer
