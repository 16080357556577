import { useRoutes } from "react-router-dom"

import AuthRoutes from "./AuthRoutes"
import AdminRoutes from "./AdminRoutes"
import MainRoutes from "./MainRoutes"

const Routes = () => {
    return useRoutes([AuthRoutes, AdminRoutes, MainRoutes])
}

export default Routes
