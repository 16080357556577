import axios from "axios"

const service = axios.create({ baseURL: process.env.REACT_APP_API })

const resetToken = () => {
    localStorage.removeItem("token")

    delete service.defaults.headers.Authorization
}

service.interceptors.response.use(
    (response) => {
        return { ...response, data: { result: response.data } }
    },

    (error) => {
        if (error.response) {
            if (error.response.status == 401) {
                resetToken()

                window.location.pathname = "/"
            }

            if (typeof error.response?.data == "string") {
                return { ...error.response, data: { error: error.response.data } }
            }

            return { ...error.response, data: error.response.data }
        }

        return { data: { error: `${error}` } }
    }
)

export default service
