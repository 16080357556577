import { ISession } from "interfaces/main/session"
import { IUserFiltered } from "interfaces/main/user"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: ISession = { user: {} }

export const sessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        reset(state) {
            state = { ...initialState }

            return { ...initialState }
        },

        updateUser(state, action: PayloadAction<IUserFiltered>) {
            state.user = action.payload
        },

        updateToken(state, action: PayloadAction<string>) {
            state.token = action.payload
        }
    }
})

export default sessionSlice.reducer
