import { IFormAny } from "interfaces/components/form"
import { ICheckboxProps } from "interfaces/components/checkbox"
import { Checkbox as MUICheckbox, Stack, Typography } from "@mui/material"

export const Checkbox = ({ name, label, value, disabled, onChange }: ICheckboxProps) => {
    const handleChange: IFormAny = (e) => {
        onChange({
            target: {
                name,
                value: e.target.checked ? "true" : ""
            }
        })
    }

    return (
        <Stack direction="row" justifyContent="center" alignItems="center">
            <MUICheckbox name={name} checked={Boolean(value)} disabled={disabled} onChange={handleChange} />

            {label && <Typography variant="h6">{label}</Typography>}
        </Stack>
    )
}
