import { useState } from "react"
import { IActionsProps } from "interfaces/components/actions"
import { IconButton, Menu, MenuItem } from "@mui/material"
import { MoreOutlined } from "@ant-design/icons"

export const Actions = ({ id, functions }: IActionsProps) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    const handleOpen = (e: any) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAction = (e: any) => {
        if (functions?.[e?.target?.id]) {
            functions[e.target.id](id)
        }

        handleClose()
    }

    return (
        <>
            <IconButton edge="end" color="secondary" onClick={handleOpen} aria-haspopup="true" aria-expanded={open ? "true" : undefined}>
                <MoreOutlined />
            </IconButton>

            <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                {functions &&
                    Object.keys(functions).map((x) => (
                        <MenuItem id={x} key={x} onClick={handleAction}>
                            {x}
                        </MenuItem>
                    ))}
            </Menu>
        </>
    )
}
