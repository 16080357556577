import AppBar from "@mui/material/AppBar"

import { styled } from "@mui/material/styles"

const MainHeaderBar = styled(AppBar, { shouldForwardProp: (prop) => prop !== "open" })<{ open: boolean }>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(!open && {
        width: `calc(100% - ${theme.spacing(7.5)})`
    }),
    ...(open && {
        marginLeft: "260px",
        width: `calc(100% - 260px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}))

export default MainHeaderBar
