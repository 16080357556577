import { IInputProps } from "interfaces/components/input"
import { OutlinedInput, Stack, Typography } from "@mui/material"

export const Input = ({
    name,
    value = "",
    error,
    label,
    maxWidth,
    placeholder,
    disabled,
    fullWidth,
    type,
    endAdornment,
    onChange
}: IInputProps) => {
    return (
        <Stack spacing={1} sx={{ width: fullWidth ? "100%" : "auto", maxWidth }}>
            {label && (
                <Typography variant="h6" color="secondary">
                    {label}
                </Typography>
            )}

            <OutlinedInput
                name={name}
                value={value}
                error={Boolean(error)}
                placeholder={placeholder}
                disabled={disabled}
                fullWidth={fullWidth}
                type={type}
                endAdornment={endAdornment}
                onChange={onChange}
            />

            {error && (
                <Typography variant="subtitle2" color="error">
                    {error}
                </Typography>
            )}
        </Stack>
    )
}
