// ==============================|| OVERRIDES - DIALOG TITLE ||============================== //

export default function DialogTitle() {
    return {
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                    fontWeight: 500
                }
            }
        }
    }
}
