import { Button, Stack, Typography } from "@mui/material"

const AuthFooter = () => {
    const handleTelegram = () => {
        window.open("https://t.me/rapidvisa_support", "_blank")
    }

    return (
        <Stack sx={{ p: 3, pt: 0 }} direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Button variant="text" color="secondary" onClick={handleTelegram}>
                <Typography variant="subtitle1">Telegram</Typography>
            </Button>
        </Stack>
    )
}

export default AuthFooter
