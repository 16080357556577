import * as ReactDOM from "react-dom/client"
import App from "./App"
import { Provider } from "react-redux"
import { setupStore } from "store"
import ThemeCustomization from "./utils/theme"
import { BrowserRouter } from "react-router-dom"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import "dayjs/locale/ru"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

import "notyf/notyf.min.css"

const rootElement = document.getElementById("root")
const root = ReactDOM.createRoot(rootElement!)

const store = setupStore()

root.render(
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <ThemeCustomization>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </ThemeCustomization>
    </LocalizationProvider>
)
