import axios from "libs/axios"

import { useState } from "react"
import { UserOutlined } from "@ant-design/icons"
import { sessionSlice } from "store/reducers/session"
import { useAppDispatch, useAppSelector } from "interfaces/redux"
import { Divider, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material"

const MainHeaderContent = () => {
    const { user } = useAppSelector((state) => state.session)
    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    const handleOpen = (e: any) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        axios.defaults.headers.Authorization = ""

        localStorage.removeItem("token")

        dispatch(sessionSlice.actions.reset())

        handleClose()
    }

    return (
        <>
            <Stack direction="row">
                <IconButton
                    edge="end"
                    color="secondary"
                    onClick={handleOpen}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    sx={{ fontSize: "18px", mr: { xs: 0, lg: -2 } }}
                >
                    <UserOutlined />
                </IconButton>
            </Stack>

            <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                <Stack sx={{ p: 2, pt: 1 }} spacing={2}>
                    <Typography variant="subtitle1" color="secondary">
                        {user.email}
                    </Typography>
                </Stack>

                <Divider />

                <Stack sx={{ pt: 1 }}>
                    <MenuItem onClick={handleLogout}>
                        <Typography color="error">Выйти</Typography>
                    </MenuItem>
                </Stack>
            </Menu>
        </>
    )
}

export default MainHeaderContent
