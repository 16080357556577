import { useState } from "react"
import { IFormObject } from "interfaces/components/form"
import { ITableHeader, ITableHeaderBar, ITableHeaderFilters } from "interfaces/components/table"
import { Button, Divider, IconButton, InputAdornment, Stack } from "@mui/material"
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined } from "@ant-design/icons"
import { Input, Line, Select } from "components"

const TableHeaderBar = ({ searchPlaceholder, values, handleCreate, handleChange, handleExtend, disabled, extended }: ITableHeaderBar) => {
    return (
        <Stack sx={{ p: 2 }} direction="row" spacing={1} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={1} alignItems="center">
                <Input
                    name="search"
                    value={values?.search}
                    placeholder={searchPlaceholder}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton disabled={disabled} type="submit" color="secondary" edge="end">
                                <SearchOutlined />
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={handleChange}
                    disabled={disabled}
                />

                <IconButton sx={{ fontSize: "18px", bgcolor: extended ? "grey.100" : "" }} onClick={handleExtend}>
                    {extended ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                </IconButton>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
                {handleCreate && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleCreate()
                        }}
                    >
                        Создать
                    </Button>
                )}
            </Stack>
        </Stack>
    )
}

const TableHeaderFilters = ({ filters, values, reset, handleChange, disabled, extended }: ITableHeaderFilters) => {
    return (
        <Stack sx={{ display: extended ? "flex" : "none", p: 2 }} spacing={2}>
            {filters &&
                filters.map((x, i) => {
                    return (
                        <Line key={i}>
                            {x &&
                                Object.keys(x).map((k) => {
                                    return (
                                        <Select
                                            key={k}
                                            name={k}
                                            value={values?.[k]}
                                            label={x[k].name}
                                            values={x[k].values}
                                            onChange={handleChange}
                                            disabled={disabled}
                                            fullWidth
                                        />
                                    )
                                })}
                        </Line>
                    )
                })}

            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Button sx={{ width: "fit-content" }} color="error" variant="outlined" disabled={disabled} onClick={reset}>
                    Сбросить
                </Button>

                <Button type="submit" sx={{ width: "fit-content" }} variant="contained" disabled={disabled}>
                    Применить
                </Button>
            </Stack>
        </Stack>
    )
}

export const TableHeader = ({
    values,
    initial,
    filters,
    searchPlaceholder,
    handleChange,
    handleCreate,
    updateState,
    disabled
}: ITableHeader) => {
    const [extended, setExtended] = useState<boolean>(false)

    const handleExtend = () => {
        setExtended(!extended)
    }

    const reset = () => {
        const reseted: IFormObject = { ...initial }

        if (values?.page) {
            reseted.page = values.page
        }

        if (values?.limit) {
            reseted.limit = values.limit
        }

        updateState({ values: reseted, updated: {} })
    }

    return (
        <Stack>
            <TableHeaderBar
                searchPlaceholder={searchPlaceholder}
                handleCreate={handleCreate}
                handleExtend={handleExtend}
                values={values}
                handleChange={handleChange}
                disabled={disabled}
                extended={extended}
            />

            {extended && <Divider />}

            <TableHeaderFilters
                filters={filters}
                reset={reset}
                values={values}
                handleChange={handleChange}
                disabled={disabled}
                extended={extended}
            />
        </Stack>
    )
}
