import UserHeader from "./header"
import MainDrawer from "./drawer"

import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Box, Container, Toolbar, useTheme } from "@mui/material"

const MainWrapper = ({ children }: { children: JSX.Element }) => {
    const theme = useTheme()
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <Box sx={{ display: "flex", width: "100%" }}>
            <UserHeader />

            <MainDrawer />

            <Box
                component="main"
                sx={{
                    transition: theme.transitions.create("padding", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                    }),
                    width: "calc(100% - 260px)",
                    flexGrow: 1,
                    pt: 2,
                    px: { xs: 0, sm: 2 }
                }}
            >
                <Toolbar sx={{ mt: "inherit" }} />
                <Container
                    maxWidth="xl"
                    sx={{
                        px: { xs: 0, sm: 2 },
                        position: "relative",
                        minHeight: "calc(100vh - 110px)",
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    {children}
                </Container>
            </Box>
        </Box>
    )
}

export default MainWrapper
