import { Theme } from "@mui/material/styles"
import { Card as MUICard, SxProps, useTheme } from "@mui/material"

export const Card = ({ children, sx }: { children?: JSX.Element | JSX.Element[]; sx?: SxProps<Theme> }) => {
    const theme = useTheme()

    return (
        <MUICard
            elevation={0}
            sx={{
                position: "relative",
                border: "1px solid",
                borderRadius: 1,
                borderColor: theme.palette.grey.A800,
                ...sx
            }}
        >
            {children}
        </MUICard>
    )
}
