import { ISelectProps } from "interfaces/components/select"
import { Select as MUISelect, Stack, Typography, MenuItem } from "@mui/material"
import { useEffect } from "react"
import { Label } from "./Label"

export const Select = ({
    name,
    value = "",
    values,
    defaultValue = "",
    label,
    maxWidth,
    placeholder,
    disabled,
    fullWidth,
    onChange
}: ISelectProps) => {
    if (!values?.hasOwnProperty(value)) {
        value = ""
    }

    useEffect(() => {
        if (values && Object.keys(values).length > 0) {
            if (!values.hasOwnProperty(value)) {
                onChange && onChange({ target: { name, value: Object.keys(values)[0] } })
            }
        }
    }, [values])

    return (
        <Label name={label} width={fullWidth ? "100%" : "fit-content"}>
            <MUISelect
                sx={{ width: fullWidth ? "100%" : "fit-content" }}
                fullWidth={fullWidth}
                name={name}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                displayEmpty
                onChange={onChange}
            >
                {defaultValue && <MenuItem value="">{defaultValue}</MenuItem>}

                {values &&
                    Object.keys(values).map((x) => (
                        <MenuItem key={x} value={x}>
                            {values?.[x] || ""}
                        </MenuItem>
                    ))}
            </MUISelect>
        </Label>
    )
}
