import { Box } from "@mui/material"
import { Card } from "components"

const AuthCard = ({ children }: { children: JSX.Element }) => (
    <Box sx={{ justifyContent: "center", display: "flex" }}>
        <Card sx={{ p: 4, width: "100%", maxWidth: "500px" }}>{children}</Card>
    </Box>
)

export default AuthCard
