import { Form } from "./Form"
import { IPageState } from "interfaces/page"
import { IDialogProps } from "interfaces/components/dialog"
import { LoaderHandler } from "components"
import { useCallback, useEffect, useState } from "react"
import { Divider, Dialog as MUIDialog, Paper, PaperProps, Stack, Typography } from "@mui/material"

const CustomPaper = ({ children }: PaperProps) => {
    return <Paper>{children}</Paper>
}

export const Dialog = ({ id, name, initial, settings, steps, width = "600px", validate, preload, onSubmit, onClose }: IDialogProps) => {
    const [step, setStep] = useState<number>(0)

    const [state, setState] = useState<IPageState>({ values: { ...initial } })

    const updateState = (values: IPageState) => {
        setState({ ...state, ...values })
    }

    const { id: settingsId, prefix, params } = settings || {}

    const open = settingsId === id

    useEffect(() => {
        const init = async () => {
            const values = preload ? await preload(params) : {}

            setStep(0)

            updateState({ values: { ...initial, ...values }, loading: false })
        }

        if (open) {
            updateState({ loading: true })

            init()
        }
    }, [initial, settings, preload])

    const handleClose = () => {
        if (!state.loading) {
            onClose && onClose()
        }
    }

    let parsedName = (
        <Stack sx={{ p: 2 }} direction="row" spacing={1} alignItems="center">
            <Typography variant="subtitle1">{name}</Typography>
        </Stack>
    )

    if (prefix) {
        parsedName = (
            <Stack sx={{ p: 2 }} direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1">{name}</Typography>
                <Typography variant="subtitle1" color="secondary.light">
                    -
                </Typography>
                <Typography variant="subtitle1" color="secondary">
                    {prefix}
                </Typography>
            </Stack>
        )
    }

    return (
        <MUIDialog PaperComponent={CustomPaper} open={Boolean(open)} onClose={handleClose}>
            <Stack sx={{ width, maxHeight: "80vh", maxWidth: "100vw", overflow: "auto" }}>
                {parsedName}

                <Divider />

                {state.loading ? (
                    <LoaderHandler />
                ) : (
                    <Form initial={state.values} validate={validate} disabled={state.loading} onSubmit={onSubmit}>
                        {({ errors, values, updateState, updateValues, handleChange, submit, disabled }) => {
                            const prevStep = useCallback(() => {
                                if (!disabled) {
                                    if (step - 1 < 0) {
                                        onClose && onClose()
                                    } else {
                                        setStep(step - 1)
                                    }
                                }
                            }, [disabled, step, onClose])

                            const nextStep = useCallback(() => {
                                if (!disabled) {
                                    if (step + 1 >= (steps?.length || 0)) {
                                        submit()
                                    } else {
                                        setStep(step + 1)
                                    }
                                }
                            }, [disabled, step, submit, steps])

                            return (
                                <>
                                    {steps &&
                                        steps.map((Step, i) => (
                                            <Step
                                                key={i}
                                                errors={errors || {}}
                                                values={values || {}}
                                                prevStep={prevStep}
                                                nextStep={nextStep}
                                                updateState={updateState}
                                                updateValues={updateValues}
                                                handleChange={handleChange}
                                                open={step == i}
                                                disabled={disabled}
                                            />
                                        ))}
                                </>
                            )
                        }}
                    </Form>
                )}
            </Stack>
        </MUIDialog>
    )
}
