import { Select } from "components"

import { IFormAny } from "interfaces/components/form"
import { Pagination, Stack } from "@mui/material"
import { ITableFooterProps } from "interfaces/components/table"
import { useEffect, useState } from "react"

const LIMITS = {
    20: "20",
    50: "50",
    100: "100",
    200: "200",
    "": "Все"
}

export const TableFooter = ({ values, count, updateValues, submit, disabled }: ITableFooterProps) => {
    const [forceUpdate, setForceUpdate] = useState<boolean>(false)

    useEffect(() => {
        if (forceUpdate) {
            submit()

            setForceUpdate(false)
        }
    }, [forceUpdate])

    const pages = values?.limit ? Math.ceil((count || 0) / values?.limit) : 1

    const handlePageUpdate = (e: any, page: number) => {
        updateValues({ page })

        setForceUpdate(true)
    }

    const handleLimitUpdate: IFormAny = (e) => {
        updateValues({ page: 1, limit: Number.parseInt(e?.target?.value) || 0 })

        setForceUpdate(true)
    }

    return (
        <Stack sx={{ p: 2 }} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Select name="limit" value={values?.limit} values={LIMITS} disabled={disabled} onChange={handleLimitUpdate} />

            <Pagination
                hideNextButton
                hidePrevButton
                variant="outlined"
                color="primary"
                count={pages}
                page={values?.page || 1}
                disabled={disabled}
                onChange={handlePageUpdate}
            />
        </Stack>
    )
}
