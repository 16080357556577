import Drawer from "@mui/material/Drawer"

import { styled, Theme, CSSObject } from "@mui/material/styles"

const openedMixin = (theme: Theme): CSSObject => ({
    width: "260px",
    borderRight: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: "hidden",
    boxShadow: theme.customShadows.z1
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7.5),
    borderRight: "none",
    boxShadow: theme.customShadows.z1
})

const MainMiniDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: "260px",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme)
    })
}))

export default MainMiniDrawer
