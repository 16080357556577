import Routes from "routes"

import { SessionContext } from "context"

const App = () => (
    <SessionContext>
        <Routes />
    </SessionContext>
)

export default App
