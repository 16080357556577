import AuthCard from "./AuthCard"
import AuthFooter from "./AuthFooter"
import AuthHeader from "./AuthHeader"

import { Box, Stack } from "@mui/material"

const AuthWrapper = ({ children }: { children: JSX.Element }) => (
    <Box sx={{ minHeight: "100vh", minWidth: "200px" }}>
        <Stack justifyContent="space-between" spacing={2} sx={{ minHeight: "100vh" }}>
            <AuthHeader />

            <AuthCard>{children}</AuthCard>

            <AuthFooter />
        </Stack>
    </Box>
)

export default AuthWrapper
